import { Form, Input, InputNumber } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import {
  getConstantValue,
  getOptionsFromEntities,
} from "../../helpers/UtilHelper";
import SearchSelect from "../common/SearchSelect";
import { BrandCrudApi, CategoryCrudApi } from "../../client/Api";
import OptionalTarbilForm from "../form/OptionalTarbilForm";

export const getProductCPColumns = (constants: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Ürün Adı", dataIndex: "name", key: "name" },
    {
      title: "Barkod",
      dataIndex: "barcode",
      key: "barcode",
      render: (value: any) => value ?? "Yok",
    },
    {
      title: "Seri No",
      dataIndex: "serialNo",
      key: "serialNo",
      render: (value: any) => value ?? "Yok",
    },
    {
      title: "Kategori",
      dataIndex: "category",
      key: "category",
      render: (value: any) => value?.name ?? "Yok",
    },
    {
      title: "Marka",
      dataIndex: "brand",
      key: "brand",
      render: (value: any) => value?.name ?? "Yok",
    },
    {
      title: "Adet",
      dataIndex: "stockCount",
      key: "stockCount",
      render: (value: any) => value ?? 0,
    },
    {
      title: "Satış Fiyatı",
      dataIndex: "sellPrice",
      key: "sellPrice",
      sorter: true,
    },
    {
      title: "Son Alış Fiyatı",
      dataIndex: "lastBuyPrice",
      key: "lastBuyPrice",
      sorter: true,
      render: (value: any) => value ?? "Yok",
    },
    {
      title: "KDV",
      dataIndex: "tax",
      key: "tax",
    },
    {
      title: "Tarbil Kaydı",
      dataIndex: "tarbilType",
      key: "tarbilType",
      render: (value: any) =>
        value
          ? getConstantValue(value, constants.tarbilTypes)
          : "Kayıtlı Değil",
    },
    {
      title: "Tarbil No",
      dataIndex: "tarbilNo",
      key: "tarbilNo",
      render: (value: any) => value ?? "Yok",
    },
  ];
};

export const getProductCPFormItems = (constants: any, isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Ürün Adı"
        rules={[required, min(2), max(255)]}
      >
        <Input placeholder="Ürün adı giriniz" />
      </Form.Item>
      <Form.Item
        name="sellPrice"
        label="Satış Fiyatı"
        rules={[required]}
        initialValue="0"
      >
        <InputNumber
          style={{ width: "100%" }}
          stringMode
          precision={2}
          min={0}
          placeholder="Satış fiyatı giriniz"
        />
      </Form.Item>
      <Form.Item name="tax" label="KDV" rules={[required]} initialValue="0">
        <InputNumber
          style={{ width: "100%" }}
          stringMode
          precision={2}
          min={0}
          placeholder="KDV giriniz"
        />
      </Form.Item>
      <Form.Item name="barcode" label="Barkod" rules={[min(2), max(255)]}>
        <Input placeholder="Barkod giriniz" />
      </Form.Item>
      <Form.Item name="serialNo" label="Seri No" rules={[min(2), max(255)]}>
        <Input placeholder="Seri no giriniz" />
      </Form.Item>
      <Form.Item name="category" label="Kategori">
        <SearchSelect
          placeholder="Kategori seçiniz"
          searchAction={(searchValue, setOptions, setLoading) =>
            CategoryCrudApi.getAll(1, 10, searchValue)
              .then((response) => {
                setOptions(getOptionsFromEntities(response["hydra:member"]));
              })
              .finally(() => setLoading(false))
          }
        />
      </Form.Item>
      <Form.Item name="brand" label="Marka">
        <SearchSelect
          placeholder="Marka seçiniz"
          searchAction={(searchValue, setOptions, setLoading) =>
            BrandCrudApi.getAll(1, 10, searchValue)
              .then((response) => {
                setOptions(getOptionsFromEntities(response["hydra:member"]));
              })
              .finally(() => setLoading(false))
          }
        />
      </Form.Item>
      <OptionalTarbilForm tarbilTypes={constants?.tarbilTypes} />
    </>
  );
};
