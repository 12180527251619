import { Typography } from "antd";
import { ProductCrudApi } from "../../client/Api";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getProductCPColumns,
  getProductCPFormItems,
} from "../../components/hook/ProductCPComponent";
import { getFormattedDate } from "../../helpers/UtilHelper";
import { useContext } from "react";
import { ConstantContext } from "../../context";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import { ArrowsAltOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const ProductCrudPage = () => {
  const constants = useContext(ConstantContext);
  const navigate = useNavigate();

  return (
    <CrudTable
      entityLabel="Ürün"
      api={ProductCrudApi}
      columns={getProductCPColumns(constants)}
      addFormItems={getProductCPFormItems(constants)}
      editFormItems={getProductCPFormItems(constants, true)}
      extraRowProcess={(row) => (
        <>
          <CrudTableProcessButton
            icon={<ArrowsAltOutlined />}
            tooltipText="Stok Hareketleri"
            onClick={() => navigate("/stok-detay/" + row.id)}
          />
        </>
      )}
      expandable={{
        expandedRowRender: (record: any) => (
          <>
            <Text strong>Oluşturulma Tarihi: </Text>
            <Text>
              {getFormattedDate(record.createdAt, "DD.MM.YYYY HH:mm:ss")}
            </Text>{" "}
            <br />
            <Text strong>Son Değişiklik Tarihi: </Text>
            <Text>
              {getFormattedDate(record.updatedAt, "DD.MM.YYYY HH:mm:ss")}
            </Text>{" "}
            <br />
          </>
        ),
      }}
      setEditFields={(row) => {
        return {
          ...row,
          brand: row.brand
            ? { label: row.brand.name, value: row.brand["@id"] }
            : undefined,
          category: row.category
            ? { label: row.category.name, value: row.category["@id"] }
            : undefined,
        };
      }}
      beforeEditOperation={(values: any) => {
        let category = null;
        if (values.category) {
          category =
            typeof values.category === "object"
              ? values.category.value
              : values.category;
        }

        let brand = null;
        if (values.brand) {
          brand =
            typeof values.brand === "object"
              ? values.brand.value
              : values.brand;
        }

        return { ...values, category: category, brand: brand };
      }}
    />
  );
};

export default ProductCrudPage;
