import {
  Button,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  Image,
  Space,
  message,
  Col,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import InputPhone from "../../components/common/InputPhone";
import { len, max, min, required } from "../../helpers/FormRuleHelper";
import { AuthApi } from "../../client/Api";
import { useState } from "react";
import { saveCredentials } from "../../helpers/AuthHelper";
import InputCode from "../../components/common/InputCode";
import { useForm } from "antd/es/form/Form";

const { Title, Text } = Typography;

const RegisterPage = () => {
  const navigate = useNavigate();
  const [registerForm] = useForm();

  const [loading, setLoading] = useState(false);
  const [isActivationStep, setIsActivationStep] = useState(false);

  const onFinish = (values: any) => {
    if (values.plainPassword !== values.plainPasswordAgain) {
      message.warning("Şifreler eşleşmiyor!");
      return;
    }

    setLoading(true);
    AuthApi.register(values)
      .then((response) => {
        message.success(response.message);
        if (response["type"] === "activation") {
          setIsActivationStep(true);
        } else if (response["type"] === "record") {
          saveCredentials(response.accessToken, response.refreshToken);
          navigate("/");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Row justify="center">
        <Image
          width={64}
          height={64}
          src="/assets/primary-circle-logo.png"
          alt="primary-logo"
          preview={false}
        />
      </Row>
      <Row justify="center">
        <Title level={4}>Hesabınızı Hemen Oluşturun</Title>
      </Row>
      <Row justify="center">
        <Text>Hesabınız var mı ?</Text>
        <Link style={{ marginLeft: 4 }} to={"/giris-yap"}>
          Giriş Yapın
        </Link>
      </Row>
      <Divider />
      <Form form={registerForm} layout="vertical" onFinish={onFinish}>
        <Space>
          <Form.Item
            name="firstname"
            label="Ad"
            rules={[required, min(2), max(30)]}
          >
            <Input prefix={<UserOutlined />} placeholder="Adınızı giriniz." />
          </Form.Item>
          <Form.Item
            name="lastname"
            label="Soyad"
            rules={[required, min(2), max(30)]}
          >
            <Input
              prefix={<UserOutlined />}
              placeholder="Soyadınızı giriniz."
            />
          </Form.Item>
        </Space>
        <Form.Item name="phone" label="Telefon" rules={[required, len(10)]}>
          <InputPhone />
        </Form.Item>
        {isActivationStep && (
          <Form.Item
            name="activationCode"
            label="Aktivasyon Kodu"
            rules={[required, len(6)]}
          >
            <InputCode />
          </Form.Item>
        )}
        <Space>
          <Form.Item
            name="plainPassword"
            label="Şifre"
            rules={[required, min(4), max(255)]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Şifrenizi giriniz."
            />
          </Form.Item>
          <Form.Item
            name="plainPasswordAgain"
            label="Şifre Tekrar"
            rules={[required, min(4), max(255)]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Şifrenizi tekrar giriniz."
            />
          </Form.Item>
        </Space>
        <Row justify="space-between" gutter={[8, 8]}>
          {isActivationStep && (
            <Col span={12}>
              <Button
                loading={loading}
                danger
                style={{ width: "100%" }}
                onClick={() => {
                  registerForm.resetFields(["activationCode"]);
                  setIsActivationStep(false);
                }}
              >
                Kod Gelmedi
              </Button>
            </Col>
          )}
          <Col span={isActivationStep ? 12 : 24}>
            <Button
              loading={loading}
              htmlType="submit"
              type="primary"
              style={{ width: "100%" }}
            >
              {isActivationStep ? "Kaydı Tamamla" : "Aktivasyon Kodu Gönder"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default RegisterPage;
