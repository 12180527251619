import { BrandCrudApi } from "../../client/Api";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getBrandCPColumns,
  getBrandCPFormItems,
} from "../../components/hook/BrandCPComponent";

const BrandCrudPage = () => {
  return (
    <CrudTable
      entityLabel="Marka"
      api={BrandCrudApi}
      columns={getBrandCPColumns()}
      addFormItems={getBrandCPFormItems()}
      editFormItems={getBrandCPFormItems(true)}
    />
  );
};

export default BrandCrudPage;
