import { Form, Input, InputNumber, Select } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import { getConstantValue, getFormattedDate } from "../../helpers/UtilHelper";
import BKDatePicker from "../common/BKDatePicker";
import dayjs from "dayjs";

const { TextArea } = Input;

export const getVaultItemCPColumns = (constants: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Açıklama", dataIndex: "description", key: "description" },
    {
      title: "Kayıt Tipi",
      dataIndex: "type",
      key: "type",
      filters: constants?.vaultItemTypes,
      render: (value: any) =>
        getConstantValue(value, constants?.vaultItemTypes),
    },
    {
      title: "Tutar",
      dataIndex: "price",
      key: "price",
      sorter: true,
    },
    {
      title: "Tarih",
      dataIndex: "date",
      key: "date",
      sorter: true,
      render: (value: any) => getFormattedDate(value, "DD.MM.YYYY HH:mm"),
    },
  ];
};

export const getVaultItemCPFormItems = (constants: any, isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item name="type" label="Kayıt Tipi" rules={[required]}>
        <Select
          placeholder="Kayıt tipi seçiniz"
          options={constants?.vaultItemTypes}
        />
      </Form.Item>
      <Form.Item name="price" label="Tutar" rules={[required]} initialValue="1">
        <InputNumber
          precision={2}
          stringMode
          min={1}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name="date"
        label="Tarih"
        rules={[required]}
        initialValue={dayjs()}
      >
        <BKDatePicker showTime placeholder="Tarih giriniz" />
      </Form.Item>
      <Form.Item name="description" label="Açıklama" rules={[min(2), max(255)]}>
        <TextArea rows={4} placeholder="Açıklama giriniz" />
      </Form.Item>
    </>
  );
};
