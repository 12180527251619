import { useParams } from "react-router-dom";
import { getCrudApi } from "../../client/CrudApi";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getVaultItemCPColumns,
  getVaultItemCPFormItems,
} from "../../components/hook/VaultItemCPComponent";
import { useContext } from "react";
import { ConstantContext } from "../../context";
import dayjs from "dayjs";

const VaultItemCrudPage = () => {
  const { id } = useParams();
  const constants = useContext(ConstantContext);

  return (
    <CrudTable
      entityLabel="Kasa Hareketi"
      api={getCrudApi("vaults/" + id + "/vault-items")}
      columns={getVaultItemCPColumns(constants)}
      addFormItems={getVaultItemCPFormItems(constants)}
      editFormItems={getVaultItemCPFormItems(constants, true)}
      setEditFields={(row) => ({
        ...row,
        id: row["@id"],
        date: dayjs(row.date),
      })}
    />
  );
};

export default VaultItemCrudPage;
