import {
  Button,
  Divider,
  Form,
  Input,
  Row,
  Image,
  Typography,
  Space,
  Col,
  message,
} from "antd";
import { LockOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import InputPhone from "../../components/common/InputPhone";
import { len, max, min, required } from "../../helpers/FormRuleHelper";
import InputCode from "../../components/common/InputCode";
import { useForm } from "antd/es/form/Form";
import { AuthApi } from "../../client/Api";

const { Title, Text } = Typography;

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [forgotPasswordForm] = useForm();

  const [loading, setLoading] = useState(false);
  const [isHaveCode, setIsHaveCode] = useState(false);

  const onFinish = (values: any) => {
    if (isHaveCode && values.plainPassword !== values.plainPasswordAgain) {
      message.warning("Şifreler eşleşmiyor!");
      return;
    }

    setLoading(true);
    AuthApi.forgotPassword(values)
      .then((response) => {
        message.success(response.message);
        if (response["type"] === "activation") {
          setIsHaveCode(true);
        } else if (response["type"] === "renew") {
          navigate("/");
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Row justify="center">
        <Image
          width={64}
          height={64}
          src="/assets/primary-circle-logo.png"
          alt="primary-logo"
          preview={false}
        />
      </Row>
      <Row justify="center">
        <Title level={4}>Şifremi Unuttum</Title>
      </Row>
      <Row justify="center">
        <Text>Şifrenizi hatırladınız mı ?</Text>
        <Link style={{ marginLeft: 4 }} to={"/giris-yap"}>
          Giriş Yapın
        </Link>
      </Row>
      <Divider />
      <Form form={forgotPasswordForm} layout="vertical" onFinish={onFinish}>
        <Form.Item name="phone" label="Telefon" rules={[required, len(10)]}>
          <InputPhone />
        </Form.Item>
        {isHaveCode && (
          <>
            <Form.Item
              name="activationCode"
              label="Aktivasyon Kodu"
              rules={[required, len(6)]}
            >
              <InputCode />
            </Form.Item>
            <Space>
              <Form.Item
                name="plainPassword"
                label="Yeni Şifre"
                rules={[required, min(4), max(255)]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Yeni şifrenizi giriniz."
                />
              </Form.Item>
              <Form.Item
                name="plainPasswordAgain"
                label="Yeni Şifre Tekrar"
                rules={[required, min(4), max(255)]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Yeni şifrenizi tekrar giriniz."
                />
              </Form.Item>
            </Space>
          </>
        )}
        <Row justify="space-between" gutter={[8, 8]}>
          {isHaveCode && (
            <Col span={12}>
              <Button
                loading={loading}
                danger
                style={{ width: "100%" }}
                onClick={() => {
                  forgotPasswordForm.resetFields([
                    "activationCode",
                    "plainPassword",
                    "plainPasswordAgain",
                  ]);
                  setIsHaveCode(false);
                }}
              >
                Kod Gelmedi
              </Button>
            </Col>
          )}
          <Col span={isHaveCode ? 12 : 24}>
            <Button
              loading={loading}
              htmlType="submit"
              type="primary"
              style={{ width: "100%" }}
            >
              {isHaveCode ? "Şifreyi Yenile" : "Aktivasyon Kodu Gönder"}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default ForgotPasswordPage;
