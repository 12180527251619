import { Form, Input } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";

export const getVaultCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Kasa Adı", dataIndex: "name", key: "name" },
    { title: "Bakiye", dataIndex: "balance", key: "balance" },
  ];
};

export const getVaultCPFormItems = (isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Kasa Adı"
        rules={[required, min(2), max(255)]}
      >
        <Input placeholder="Kasa adı giriniz" />
      </Form.Item>
    </>
  );
};
