import { Form, Input, InputNumber } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import { getConstantValue } from "../../helpers/UtilHelper";
import OptionalCustomerItemTypeForm from "../form/OptionalCustomerItemTypeForm";

const { TextArea } = Input;

export const getCustomerItemCPColumns = (constants: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Açıklama",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Kayıt Tipi",
      dataIndex: "type",
      key: "type",
      filters: constants?.customerItemTypes,
      render: (value: any) =>
        getConstantValue(value, constants?.customerItemTypes),
    },
    {
      title: "Tutar",
      dataIndex: "price",
      key: "price",
      sorter: true,
    },
  ];
};

export const getCustomerItemCPFormItems = (constants: any, isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item name="price" label="Tutar" rules={[required]} initialValue="1">
        <InputNumber
          precision={2}
          stringMode
          min={1}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <OptionalCustomerItemTypeForm />
      <Form.Item
        name="description"
        label="Açıklama"
        rules={[required, min(2), max(255)]}
      >
        <TextArea placeholder="Açıklama giriniz" />
      </Form.Item>
    </>
  );
};
