import { Form, Input, message, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";
import { max, min, required } from "../../helpers/FormRuleHelper";
import { AuthApi, ClinicCrudApi } from "../../client/Api";
import { UserContext } from "../../context";

export interface IClinicAddModalRefMethods {
  setOpen: (open: boolean) => void;
}

const ClinicAddModal = forwardRef((_, ref) => {
  const { setUser } = useContext(UserContext);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({ setOpen: setOpen }));

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Klinik Ekle"
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={() =>
        form.validateFields().then((values) => {
          setLoading(true);
          ClinicCrudApi.create(values)
            .then(async () => {
              message.success("Klinik Oluşturuldu.");
              await AuthApi.currentUser().then((response) => setUser(response));
              onCancel();
            })
            .finally(() => setLoading(false));
        })
      }
      cancelText="Vazgeç"
      okText="Ekle"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Klinik Adı"
          rules={[required, min(2), max(30)]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default ClinicAddModal;
