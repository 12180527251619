import CrudTable from "../../components/common/crud/CrudTable";
import { getInvoiceCPColumns } from "../../components/hook/InvoiceCPComponent";
import { InvoiceCrudApi } from "../../client/Api";
import { useContext } from "react";
import { ConstantContext } from "../../context";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import { useNavigate } from "react-router-dom";
import { PlusOutlined, EditOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";

const InvoiceCrudPage = () => {
  const navigate = useNavigate();
  const constants = useContext(ConstantContext);

  return (
    <CrudTable
      entityLabel="Fatura"
      api={InvoiceCrudApi}
      hideEditProcess
      hideAddProcess
      columns={getInvoiceCPColumns(constants)}
      expandable={{
        expandedRowRender: (record: any) => (
          <>
            <Row style={{ borderBottom: "1px solid black" }}>
              <Col span={5}>Ürün</Col>
              <Col span={2}>Miktar</Col>
              <Col span={3}>Birim Fiyat</Col>
              <Col span={3}>İskonto (TL)</Col>
              <Col span={3}>KDV</Col>
              <Col span={4}>KDV Tutarı</Col>
              <Col span={4}>Satır Tutarı</Col>
            </Row>
            {record.invoiceItems.map((invoiceItem: any) => (
              <Row style={{ borderBottom: "1px solid black" }}>
                <Col span={5}>{invoiceItem.product.name}</Col>
                <Col span={2}>{invoiceItem.quantity}</Col>
                <Col span={3}>{invoiceItem.price}₺</Col>
                <Col span={3}>{invoiceItem.discount}₺</Col>
                <Col span={3}>%{invoiceItem.taxRate}</Col>
                <Col span={4}>{invoiceItem.taxAmount}₺</Col>
                <Col span={4}>{invoiceItem.rowAmount}₺</Col>
              </Row>
            ))}
            <Row>
              <Col offset={7} span={3}>
                Alt Tutar
              </Col>
              <Col span={4}>Toplam İskonto</Col>
              <Col offset={2} span={4}>
                Toplam KDV
              </Col>
              <Col span={4}>Toplam Tutar (KDV Dahil)</Col>

              <Col offset={7} span={3}>
                {record.totalLowerAmount}₺
              </Col>
              <Col span={4}>{record.totalDiscount}₺</Col>
              <Col offset={2} span={4}>
                {record.totalTaxAmount}₺
              </Col>
              <Col span={4}>{record.totalAmount}₺</Col>
            </Row>
            <br />
            <span>Açıklama: {record.note}</span>
          </>
        ),
      }}
      extraRowProcess={(row) => (
        <CrudTableProcessButton
          tooltipText="Fatura Düzenle"
          icon={<EditOutlined />}
          onClick={() => navigate("/fatura-duzenle/" + row.id)}
        />
      )}
      extraTitleProcess={() => (
        <CrudTableProcessButton
          tooltipText="Fatura Oluştur"
          icon={<PlusOutlined />}
          onClick={() => navigate("/fatura-olustur")}
        />
      )}
    />
  );
};

export default InvoiceCrudPage;
