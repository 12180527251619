import { Form, Row, Select, Typography } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import { useWatch } from "antd/es/form/Form";
import { useContext, useEffect, useState } from "react";
import { ConstantContext } from "../../context";
import { VaultCrudApi } from "../../client/Api";
import { getOptionsFromEntities } from "../../helpers/UtilHelper";
import dayjs from "dayjs";
import BKDatePicker from "../common/BKDatePicker";

const { Text } = Typography;

const OptionalCustomerItemTypeForm = () => {
  const type = useWatch("type");
  const constants = useContext(ConstantContext);
  const [vaultOptions, setVaultOptions] = useState([]);

  useEffect(() => {
    VaultCrudApi.getAll(1, 1000).then((response) =>
      setVaultOptions(getOptionsFromEntities(response["hydra:member"]))
    );
  }, []);

  return (
    <>
      <Form.Item name="type" label="Kayıt Tipi" rules={[required]}>
        <Select
          placeholder="Kayıt tipi seçiniz"
          options={constants?.customerItemTypes}
        />
      </Form.Item>
      {(type === "payment" || type === "collect") && (
        <div
          style={{
            border: "1px dashed black",
            padding: "8px",
            margin: "8px 0px",
          }}
        >
          <Row justify="center">
            <Text strong>Kasa Hareketi</Text>
          </Row>
          <Form.Item name="vault" label="Kasa" rules={[required]}>
            <Select placeholder="Kasa seçiniz" options={vaultOptions} />
          </Form.Item>
          <Form.Item
            name="paymentType"
            label="Ödeme Yöntemi"
            rules={[required]}
          >
            <Select
              placeholder="Ödeme yöntemi seçiniz"
              options={constants?.paymentTypes}
            />
          </Form.Item>
          <Form.Item
            name="date"
            label="Ödeme Tarih"
            rules={[required]}
            initialValue={dayjs()}
          >
            <BKDatePicker />
          </Form.Item>
        </div>
      )}
    </>
  );
};

export default OptionalCustomerItemTypeForm;
