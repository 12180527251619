import { Typography } from "antd";
import { CustomerCrudApi } from "../../client/Api";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getCustomerCPColumns,
  getCustomerCPFormItems,
} from "../../components/hook/CustomerCPComponent";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import { ArrowsAltOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Text, Title, Paragraph } = Typography;

const CustomerCrudPage = () => {
  const navigate = useNavigate();

  return (
    <CrudTable
      entityLabel="Cari"
      api={CustomerCrudApi}
      columns={getCustomerCPColumns()}
      addFormItems={getCustomerCPFormItems()}
      editFormItems={getCustomerCPFormItems(true)}
      extraRowProcess={(row) => (
        <>
          <CrudTableProcessButton
            icon={<ArrowsAltOutlined />}
            tooltipText="Cari Hareketleri"
            onClick={() => navigate("/cari-detay/" + row.id)}
          />
        </>
      )}
      expandable={{
        expandedRowRender: (record: any) => (
          <>
            <Title level={5}>Adres</Title>
            <Text>{record.address}</Text>
            <Title level={5}>Not</Title>
            <Paragraph style={{ width: "70vw" }}>{record.note}</Paragraph>
          </>
        ),
      }}
    />
  );
};

export default CustomerCrudPage;
