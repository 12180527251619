import { InputNumber } from "antd";
import { KeyOutlined } from "@ant-design/icons";

const InputCode = (props: any) => {
  return (
    <InputNumber
      {...props}
      style={{ width: "100%" }}
      controls={false}
      prefix={<KeyOutlined />}
      placeholder="xxxxxx"
      stringMode
      precision={0}
      maxLength={6}
    />
  );
};

export default InputCode;
