import { Form, Input, Select } from "antd";
import { useWatch } from "antd/es/form/Form";
import { max, min, required } from "../../helpers/FormRuleHelper";

interface IOptionalTarbilFormProps {
  tarbilTypes?: any;
}

const OptionalTarbilForm = (props: IOptionalTarbilFormProps) => {
  const tarbilType = useWatch("tarbilType");

  return (
    <>
      <Form.Item name="tarbilType" label="Tarbil Kaydı">
        <Select
          placeholder="Kayıtlı Değil"
          allowClear
          options={props.tarbilTypes}
        />
      </Form.Item>
      {tarbilType && (
        <Form.Item
          name="tarbilNo"
          label="Tarbil No"
          rules={[required, min(2), max(255)]}
        >
          <Input placeholder="Tarbil no giriniz" />
        </Form.Item>
      )}
    </>
  );
};

export default OptionalTarbilForm;
