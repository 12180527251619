import { Grid, Layout, Menu, Row, Image, Drawer } from "antd";
import { ItemType, MenuItemType } from "antd/es/menu/interface";
import {
  HomeOutlined,
  UserOutlined,
  ProductOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";

const { useBreakpoint } = Grid;
const { Sider } = Layout;

const menuItems: ItemType<MenuItemType>[] = [
  {
    label: "Dashboard",
    key: "/",
    icon: <HomeOutlined />,
  },
  {
    label: "Cariler",
    key: "/cariler",
    icon: <UserOutlined />,
  },
  {
    label: "Ürünler",
    key: "urunler",
    icon: <ProductOutlined />,
    children: [
      {
        label: "Ürünler",
        key: "/urunler",
      },
      {
        label: "Kategoriler",
        key: "/kategoriler",
      },
      {
        label: "Markalar",
        key: "/markalar",
      },
    ],
  },
  {
    label: "Muhasebe",
    key: "muhasebe",
    icon: <DollarOutlined />,
    children: [
      {
        label: "Kasalar",
        key: "/kasalar",
      },
      {
        label: "Faturalar",
        key: "/faturalar",
      },
    ],
  },
];

interface ISideMenuProps {
  drawerCollapsed: boolean;
  setDrawerCollapsed: (drawerCollapsed: boolean) => void;
}

const SideMenu = (props: ISideMenuProps) => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();

  const content = (
    <>
      <Row justify="center" align="middle" style={{ height: 64 }}>
        <Image
          width={48}
          height={48}
          src="/assets/primary-circle-logo.png"
          alt="primary-logo"
          preview={false}
        />
      </Row>
      <Menu
        selectedKeys={[location?.pathname]}
        mode="inline"
        items={menuItems}
        onClick={({ key }) => navigate(key)}
      />
    </>
  );

  return screens.md ? (
    <Sider
      defaultCollapsed
      theme="light"
      collapsible
      width={240}
      collapsedWidth={64}
      style={{
        zIndex: 100,
        boxShadow: "rgba(19, 25, 32, 0.2) 0px 8px 8px",
      }}
    >
      {content}
    </Sider>
  ) : (
    <Drawer
      closable={false}
      width={300}
      placement="left"
      open={props.drawerCollapsed}
      onClose={() => props.setDrawerCollapsed(!props.drawerCollapsed)}
    >
      {content}
    </Drawer>
  );
};

export default SideMenu;
