import { useContext, useRef } from "react";
import { Badge, Button, Card, Col, Row, Spin, Tooltip, Typography } from "antd";
import { ClinicContext, ConstantContext, UserContext } from "../context";
import { PlusOutlined } from "@ant-design/icons";
import { getConstantValue, getFormattedDate } from "../helpers/UtilHelper";
import ClinicAddModal, {
  IClinicAddModalRefMethods,
} from "../components/modal/ClinicAddModal";
import Cookies from "js-cookie";
import { colors } from "../theme";
import { InfoCircleOutlined } from "@ant-design/icons";
import CouponUseModal, {
  ICouponUseModalRefMethods,
} from "../components/modal/CouponUseModal";

const { Title, Text } = Typography;

const ClinicPage = () => {
  const clinicAddModalRef = useRef<IClinicAddModalRefMethods>();
  const couponUseModalRef = useRef<ICouponUseModalRefMethods>();

  const constants = useContext(ConstantContext);
  const { user } = useContext(UserContext);
  const { selectedClinic, setSelectedClinic } = useContext(ClinicContext);

  const getTooltipText = (userClinic: any) =>
    userClinic.clinic.isExpired
      ? "Kliniğin kullanım süresi dolmuş. " +
        (userClinic.role === "ROLE_ADMIN"
          ? "Lütfen kupon kodu giriniz."
          : "Yöneticiniz ile iletişime geçiniz.")
      : "";

  return (
    <>
      <Row justify="center">
        <Title level={4}>Kliniklerim</Title>
      </Row>
      <Row justify="center" gutter={[24, 8]}>
        {user ? (
          user.userClinics.map((userClinic: any, index: number) => {
            const isClinicAdmin = userClinic.role === "ROLE_ADMIN";
            const isClinicExpired = userClinic.clinic.isExpired;
            const isSelectedClinicBorder =
              selectedClinic?.id === userClinic.clinic.id
                ? colors.success
                : colors.white;

            return (
              <Col key={index}>
                <Badge.Ribbon
                  placement="start"
                  text={getConstantValue(userClinic.role, constants?.roles)}
                >
                  <Tooltip title={getTooltipText(userClinic)}>
                    <Card
                      rootClassName="card-wrapper"
                      style={{
                        height: "100%",
                        width: 280,
                        borderWidth: 2,
                        borderColor: isSelectedClinicBorder,
                      }}
                    >
                      <Row justify="end">
                        <Tooltip
                          title={
                            isClinicExpired
                              ? ""
                              : "Paketiniz " +
                                getFormattedDate(userClinic.clinic.expireDate) +
                                " tarihinde bitecektir."
                          }
                        >
                          <InfoCircleOutlined
                            style={{ fontSize: 18, cursor: "pointer" }}
                          />
                        </Tooltip>
                      </Row>
                      <Row style={{ height: "100%" }} gutter={[16, 16]}>
                        <Col span={24}>
                          <Text>{userClinic.clinic.name}</Text>
                        </Col>
                        <Col span={24}>
                          <Row justify="end" gutter={[8, 8]}>
                            {isClinicAdmin && isClinicExpired && (
                              <Col>
                                <Button
                                  size="small"
                                  type="primary"
                                  onClick={() => {
                                    if (!isClinicAdmin || !isClinicExpired)
                                      return;
                                    couponUseModalRef.current?.form.setFieldValue(
                                      "clinicId",
                                      userClinic.clinic.id
                                    );
                                    couponUseModalRef.current?.setOpen(true);
                                  }}
                                >
                                  Kod Kullan
                                </Button>
                              </Col>
                            )}
                            <Col>
                              <Button
                                size="small"
                                type="primary"
                                disabled={isClinicExpired}
                                onClick={() => {
                                  if (isClinicExpired) return;
                                  setSelectedClinic(userClinic.clinic);
                                  Cookies.set(
                                    "clinic",
                                    JSON.stringify(userClinic.clinic)
                                  );
                                }}
                              >
                                Seç
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Tooltip>
                </Badge.Ribbon>
              </Col>
            );
          })
        ) : (
          <Spin />
        )}
        <Col>
          <Tooltip title="Klinik Ekle">
            <Button
              onClick={() => clinicAddModalRef.current?.setOpen(true)}
              type="dashed"
              style={{ width: 80, height: 80 }}
              icon={<PlusOutlined />}
            />
          </Tooltip>
        </Col>
      </Row>
      <ClinicAddModal ref={clinicAddModalRef} />
      <CouponUseModal ref={couponUseModalRef} />
    </>
  );
};

export default ClinicPage;
