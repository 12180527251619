import { Form, Input, message, Modal } from "antd";
import { useForm } from "antd/es/form/Form";
import { forwardRef, useContext, useImperativeHandle, useState } from "react";

import { max, min, required } from "../../helpers/FormRuleHelper";
import { AuthApi, CouponApi } from "../../client/Api";

import { UserContext } from "../../context";
import { FormInstance } from "antd/lib";

export interface ICouponUseModalRefMethods {
  form: FormInstance;
  setOpen: (open: boolean) => void;
}

const CouponUseModal = forwardRef((_, ref) => {
  const { setUser } = useContext(UserContext);
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, () => ({ form: form, setOpen: setOpen }));

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  return (
    <Modal
      title="Kupon Kullan"
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={() =>
        form.validateFields().then((values) => {
          setLoading(true);
          CouponApi.use(values)
            .then(async (response) => {
              message.success(response.message);
              await AuthApi.currentUser().then((response) => setUser(response));
              onCancel();
            })
            .finally(() => setLoading(false));
        })
      }
      cancelText="Vazgeç"
      okText="Kullan"
    >
      <Form form={form} layout="vertical">
        <Form.Item hidden name="clinicId" rules={[required]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="couponCode"
          label="Kupon Kodu"
          rules={[required, min(2), max(30)]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default CouponUseModal;
