import { getConstantValue, getFormattedDate } from "../../helpers/UtilHelper";
import TableSearchFilter from "../common/TableSearchFilter";
import ProductSearchSelect from "../form/ProductSearchSelect";

export const getInvoiceCPColumns = (constants: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Fatura No", dataIndex: "name", key: "name" },
    {
      title: "Fatura Tipi",
      dataIndex: "type",
      key: "type",
      filters: constants?.invoiceTypes,
      render: (value: any) => getConstantValue(value, constants?.invoiceTypes),
    },
    {
      title: "Fatura Tarihi",
      dataIndex: "date",
      key: "date",
      sorter: true,
      render: (value: any) => getFormattedDate(value, "DD.MM.YYYY HH:mm"),
    },
    {
      title: "Müşteri/Tedarikçi",
      dataIndex: "customer",
      key: "customer",
      render: (value: any) => value?.name ?? "Yok",
    },
    {
      title: "Personel",
      dataIndex: "employee",
      key: "employee",
      render: (value: any) => value?.fullName ?? "Yok",
    },
    {
      title: "Ürün Sayısı",
      dataIndex: "productCount",
      key: "invoiceItems",
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <ProductSearchSelect
            multiple
            style={{ width: 250 }}
            optionValueKey="id"
            value={props.selectedKeys}
            onChange={(value: any) => props.setSelectedKeys(value)}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Toplam Tutar",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
  ];
};
