import { useEffect, useState } from "react";
import { ConstantContext, UserContext, ClinicContext } from "../../context";
import { AuthApi, GeneralApi } from "../../client/Api";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Button, Grid, Layout, message, Row } from "antd";
import { isLoggedIn } from "../../helpers/AuthHelper";
import { loginPath } from "../../router";
import ProfileMenu from "./ProfileMenu";
import SideMenu from "./SideMenu";
import { colors } from "../../theme";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { getClinic } from "../../helpers/UtilHelper";

const { Header, Content } = Layout;
const { useBreakpoint } = Grid;

const SiderLayout = () => {
  const screens = useBreakpoint();
  const navigate = useNavigate();
  const location = useLocation();
  const [constants, setConstants] = useState<any>();
  const [user, setUser] = useState<any>();
  const [drawerCollapsed, setDrawerCollapsed] = useState<any>();
  const [selectedClinic, setSelectedClinic] = useState<any>();
  const [isClinicSetted, setIsClinicSetted] = useState(false);

  useEffect(() => {
    if (!isLoggedIn()) return;
    GeneralApi.getConstants().then((response) => setConstants(response));
    AuthApi.currentUser().then((response) => setUser(response));
    setSelectedClinic(getClinic());
    setIsClinicSetted(true);
  }, []);

  useEffect(() => {
    if (!isLoggedIn()) return;
    if (
      isClinicSetted &&
      !selectedClinic &&
      location.pathname !== "/klinik-degistir"
    ) {
      message.warning("Lütfen işlem yapmak istediğiniz kliniği seçiniz.");
      navigate("/klinik-degistir");
    }
  }, [isClinicSetted, selectedClinic, navigate, location.pathname]);

  return !isLoggedIn() ? (
    <Navigate to={loginPath} />
  ) : (
    <ConstantContext.Provider value={constants}>
      <UserContext.Provider value={{ user, setUser }}>
        <ClinicContext.Provider value={{ selectedClinic, setSelectedClinic }}>
          <Layout style={{ minHeight: "100vh" }}>
            <SideMenu
              drawerCollapsed={drawerCollapsed}
              setDrawerCollapsed={setDrawerCollapsed}
            />
            <Layout>
              <Header style={{ padding: "0px 32px", background: colors.white }}>
                <Row
                  style={{ height: "100%" }}
                  justify={screens.md ? "end" : "space-between"}
                  align="middle"
                >
                  {!screens.md && (
                    <Button
                      type="text"
                      size="large"
                      icon={<MenuUnfoldOutlined />}
                      onClick={() => setDrawerCollapsed(!drawerCollapsed)}
                    />
                  )}
                  <ProfileMenu />
                </Row>
              </Header>
              <Content style={{ padding: 16, background: colors.background }}>
                <Outlet />
              </Content>
            </Layout>
          </Layout>
        </ClinicContext.Provider>
      </UserContext.Provider>
    </ConstantContext.Provider>
  );
};

export default SiderLayout;
