import { InputNumber } from "antd";

const InputTaxNo = (props: any) => {
  return (
    <InputNumber
      {...props}
      style={{ width: "100%" }}
      controls={false}
      stringMode
      precision={0}
      maxLength={11}
    />
  );
};

export default InputTaxNo;
