import CrudTable from "../../components/common/crud/CrudTable";
import {
  getVaultCPColumns,
  getVaultCPFormItems,
} from "../../components/hook/VaultCPComponent";
import { VaultCrudApi } from "../../client/Api";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import { useNavigate } from "react-router-dom";
import { ArrowsAltOutlined } from "@ant-design/icons";

const VaultCrudPage = () => {
  const navigate = useNavigate();

  return (
    <CrudTable
      entityLabel="Kasa"
      api={VaultCrudApi}
      columns={getVaultCPColumns()}
      addFormItems={getVaultCPFormItems()}
      editFormItems={getVaultCPFormItems(true)}
      extraRowProcess={(row) => (
        <>
          <CrudTableProcessButton
            icon={<ArrowsAltOutlined />}
            tooltipText="Kasa Hareketleri"
            onClick={() => navigate("/kasa-detay/" + row.id)}
          />
        </>
      )}
    />
  );
};

export default VaultCrudPage;
