import { useParams } from "react-router-dom";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getStockCPColumns,
  getStockCPFormItems,
} from "../../components/hook/StockCPComponent";
import { getCrudApi } from "../../client/CrudApi";
import { useContext } from "react";
import { ConstantContext } from "../../context";

const StockCrudPage = () => {
  const { id } = useParams();
  const constants = useContext(ConstantContext);

  return (
    <CrudTable
      hideSearchBar
      entityLabel="Stok Hareketi"
      api={getCrudApi("products/" + id + "/stocks")}
      columns={getStockCPColumns(constants)}
      addFormItems={getStockCPFormItems(constants)}
      editFormItems={getStockCPFormItems(constants, true)}
      setEditFields={(row) => ({
        ...row,
        id: row["@id"],
        customer: row.customer
          ? { label: row.customer.name, value: row.customer["@id"] }
          : undefined,
      })}
      beforeEditOperation={(values: any) => {
        let customer = null;
        if (values.customer) {
          customer =
            typeof values.customer === "object"
              ? values.customer.value
              : values.customer;
        }

        return { ...values, customer: customer };
      }}
    />
  );
};

export default StockCrudPage;
