import { Form, Input, InputNumber, Select } from "antd";
import { required } from "../../helpers/FormRuleHelper";
import {
  getConstantValue,
  getFormattedDate,
  getOptionsFromEntities,
} from "../../helpers/UtilHelper";
import SearchSelect from "../common/SearchSelect";
import { CustomerCrudApi } from "../../client/Api";

export const getStockCPColumns = (constants: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Hareket Tipi",
      dataIndex: "type",
      key: "type",
      filters: constants?.stockTypes,
      render: (value: any) => getConstantValue(value, constants?.stockTypes),
    },
    { title: "Adet/Miktar", dataIndex: "quantity", key: "quantity" },
    {
      title: "Tutar",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Müşteri/Tedarikçi",
      dataIndex: "customer",
      key: "customer",
      render: (value: any) => (value ? value.name : "Yok"),
    },
    {
      title: "Tarih",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value: any) => getFormattedDate(value, "DD.MM.YYYY HH:mm:ss"),
    },
  ];
};

export const getStockCPFormItems = (constants: any, isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="quantity"
        label="Adet/Miktar"
        rules={[required]}
        initialValue="1"
      >
        <InputNumber
          precision={2}
          stringMode
          min={1}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item name="price" label="Tutar" rules={[required]} initialValue="0">
        <InputNumber
          precision={2}
          stringMode
          min={0}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item name="type" label="Hareket Tipi" rules={[required]}>
        <Select
          placeholder="Hareket tipi seçiniz"
          options={constants?.stockTypes}
        />
      </Form.Item>
      <Form.Item name="customer" label="Müşteri/Tedarikçi">
        <SearchSelect
          placeholder="Müşteri/Tedarikçi seçiniz"
          searchAction={(searchValue, setOptions, setLoading) =>
            CustomerCrudApi.getAll(1, 10, searchValue)
              .then((response) => {
                setOptions(getOptionsFromEntities(response["hydra:member"]));
              })
              .finally(() => setLoading(false))
          }
        />
      </Form.Item>
    </>
  );
};
