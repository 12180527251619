import { Form, Input } from "antd";
import { email, len, max, min, required } from "../../helpers/FormRuleHelper";
import InputPhone from "../common/InputPhone";
import InputTaxNo from "../common/InputTaxNo";

const { TextArea } = Input;

export const getCustomerCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Cari Adı", dataIndex: "name", key: "name" },
    { title: "TC/Vergi No", dataIndex: "taxNo", key: "taxNo" },
    { title: "Telefon", dataIndex: "phone", key: "phone" },
    { title: "E-posta", dataIndex: "email", key: "email" },
    { title: "Bakiye", dataIndex: "balance", key: "balance" },
  ];
};

export const getCustomerCPFormItems = (isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Cari Adı"
        rules={[required, min(2), max(255)]}
      >
        <Input placeholder="Cari adı giriniz" />
      </Form.Item>
      <Form.Item
        name="taxNo"
        label="TC/Vergi No"
        rules={[required, min(10), max(11)]}
        initialValue="11111111111"
      >
        <InputTaxNo placeholder="TC/Vergi No giriniz." />
      </Form.Item>
      <Form.Item name="phone" label="Telefon" rules={[len(10)]}>
        <InputPhone />
      </Form.Item>
      <Form.Item name="email" label="E-posta" rules={[email, max(255)]}>
        <Input placeholder="E-posta giriniz." />
      </Form.Item>
      <Form.Item name="address" label="Adres" rules={[max(255)]}>
        <TextArea rows={2} placeholder="Adres giriniz." />
      </Form.Item>
      <Form.Item name="note" label="Not" rules={[max(3000)]}>
        <TextArea rows={5} placeholder="Not giriniz." />
      </Form.Item>
    </>
  );
};
