import { CategoryCrudApi } from "../../client/Api";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getCategoryCPColumns,
  getCategoryCPFormItems,
} from "../../components/hook/CategoryCPComponent";

const CategoryCrudPage = () => {
  return (
    <CrudTable
      entityLabel="Kategori"
      api={CategoryCrudApi}
      columns={getCategoryCPColumns()}
      addFormItems={getCategoryCPFormItems()}
      editFormItems={getCategoryCPFormItems(true)}
    />
  );
};

export default CategoryCrudPage;
