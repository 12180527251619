import { useParams } from "react-router-dom";
import { getCrudApi } from "../../client/CrudApi";
import CrudTable from "../../components/common/crud/CrudTable";
import {
  getCustomerItemCPColumns,
  getCustomerItemCPFormItems,
} from "../../components/hook/CustomerItemCPComponent";
import { useContext } from "react";
import { ConstantContext } from "../../context";
import dayjs from "dayjs";

const CustomerItemCrudPage = () => {
  const { id } = useParams();
  const constants = useContext(ConstantContext);

  return (
    <CrudTable
      entityLabel="Cari Hareketi"
      api={getCrudApi("customers/" + id + "/customer-items")}
      columns={getCustomerItemCPColumns(constants)}
      addFormItems={getCustomerItemCPFormItems(constants)}
      editFormItems={getCustomerItemCPFormItems(constants, true)}
      setEditFields={(row) => ({
        ...row,
        id: row["@id"],
        date: dayjs(row.date),
      })}
    />
  );
};

export default CustomerItemCrudPage;
