import { InputNumber } from "antd";

const InputPhone = (props: any) => {
  return (
    <InputNumber
      {...props}
      style={{ width: "100%" }}
      controls={false}
      prefix={"+90"}
      placeholder="5xxxxxxxxx"
      stringMode
      precision={0}
      maxLength={10}
    />
  );
};

export default InputPhone;
