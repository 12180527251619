import { Form, FormInstance, Input, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { AuthApi } from "../../client/Api";
import { max, min, required } from "../../helpers/FormRuleHelper";
import { UserContext } from "../../context";

export interface IProfileUpdateModalRefMethods {
  setOpen: (open: boolean) => void;
  form: FormInstance;
}

const ProfileUpdateModal = forwardRef((_, ref) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const { user, setUser } = useContext(UserContext);

  useImperativeHandle(ref, () => ({
    setOpen: setOpen,
    form: form,
  }));

  const onCancel = () => {
    setOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (!user) return;
    form.setFieldsValue(user);
  }, [user, form, open]);

  return (
    <Modal
      title="Profili Güncelle"
      open={open}
      confirmLoading={loading}
      onCancel={onCancel}
      onOk={() =>
        form.validateFields().then((values) => {
          setLoading(true);
          AuthApi.profileUpdate(values)
            .then((response) => {
              setUser(response.user);
              message.success(response.message);
              onCancel();
            })
            .finally(() => setLoading(false));
        })
      }
      cancelText="Vazgeç"
      okText="Güncelle"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="İsim"
          name="firstname"
          rules={[required, min(2), max(30)]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Soyisim"
          name="lastname"
          rules={[required, min(2), max(30)]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Telefon" name="phone">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
});

export default ProfileUpdateModal;
