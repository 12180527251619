import { Form, Input } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";

export const getCategoryCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Kategori Adı", dataIndex: "name", key: "name" },
  ];
};

export const getCategoryCPFormItems = (isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item
        name="name"
        label="Kategori Adı"
        rules={[required, min(2), max(255)]}
      >
        <Input placeholder="Kategori adı giriniz" />
      </Form.Item>
    </>
  );
};
