import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Row,
  Typography,
  Image,
} from "antd";
import { Link, useNavigate } from "react-router-dom";
import { LockOutlined } from "@ant-design/icons";
import { len, max, min, required } from "../../helpers/FormRuleHelper";
import InputPhone from "../../components/common/InputPhone";
import { useEffect, useState } from "react";
import { AuthApi } from "../../client/Api";
import { saveCredentials } from "../../helpers/AuthHelper";
import Cookies from "js-cookie";
import { useForm } from "antd/es/form/Form";

const { Title, Text } = Typography;

const LoginPage = () => {
  const navigate = useNavigate();
  const [loginForm] = useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = (values: any) => {
    setLoading(true);
    AuthApi.login(values.phone, values.password)
      .then(({ accessToken, refreshToken }) => {
        if (values.rememberMe === true) Cookies.set("rememberMe", values.phone);
        else Cookies.remove("rememberMe");

        saveCredentials(accessToken, refreshToken);
        navigate("/");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    const phone = Cookies.get("rememberMe");
    if (!phone) return;
    loginForm.setFieldsValue({ phone: phone, rememberMe: true });
  }, [loginForm]);

  return (
    <>
      <Row justify="center">
        <Image
          width={64}
          height={64}
          src="/assets/primary-circle-logo.png"
          alt="primary-logo"
          preview={false}
        />
      </Row>
      <Row justify="center">
        <Title level={4}>Hesabınıza Giriş Yapın</Title>
      </Row>
      <Row justify="center">
        <Text>Henüz kayıt olmadınız mı ?</Text>
        <Link style={{ marginLeft: 4 }} to={"/kayit-ol"}>
          Kayıt Ol
        </Link>
      </Row>
      <Divider />
      <Form form={loginForm} layout="vertical" onFinish={onFinish}>
        <Form.Item name="phone" label="Telefon" rules={[required, len(10)]}>
          <InputPhone />
        </Form.Item>
        <Form.Item
          name="password"
          label="Şifre"
          rules={[required, min(4), max(255)]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Şifrenizi giriniz."
          />
        </Form.Item>
        <Form.Item>
          <Row justify="space-between">
            <Form.Item name="rememberMe" valuePropName="checked">
              <Checkbox>Beni Hatırla</Checkbox>
            </Form.Item>
            <Link to={"/sifremi-unuttum"}>Şifremi unuttum</Link>
          </Row>
        </Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          style={{ width: "100%" }}
        >
          Giriş Yap
        </Button>
      </Form>
    </>
  );
};

export default LoginPage;
